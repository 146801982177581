/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/jquery/aacom/utilities/aaUtilities-2.1.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/legacy/utilities/aa-utilities-2.1.js
**/

/* global closePageSlide:true */

/**********************************************************************
? METADATA:
    Filename: aaUtilities-2.1.js
    Location: /content/js/jquery/misc/
    License: Copyright 2013, AA.com
    Created on: 06/01/13
    Version: 2.1.0
    UX: David Perez, Oscar Perez. (To be modified only by UX)
    Description: Creation of one baseline file with utility functions.
    Version Features:
        + Updated Quickflip functionality
        + Added on open/close callbacks
        + Added position option
        + Added margin/padding support for rebrand dialogs
        + Added busy state for ajax
        + Added adaptive support
**********************************************************************/
var activeDialog = '';
var dialogArray = {};
var dialogLinkObj;

function aa_Utilities () {
    this.aaDialog = function(name,attributes) {
        return new aa_Utilities_Dialog(name,attributes);
    };
    this.aaFormat = new aa_Utilities_Format();
}

function aa_Utilities_Dialog(dialog, props) {
    var self = this;

    //find Dialog
    var dialogObj = '',
        dialogName = '';
    if (dialog !== undefined) {
        if (typeof(dialog) == 'object') {
            if (dialog.attr('id') !== undefined) {
                dialogObj = '#' + dialog.attr('id');
                dialogName= dialog.attr('id');
            }
        } else if (typeof(dialog) == 'string') {
            if (dialog.indexOf('#') === 0) {
                dialogObj = dialog;
                dialogName= dialog.replace(/#/,'');
            } else {
                dialogObj = '#' + dialog + 'Dialog';
                dialogName= dialog;
            }
        }
    }
    var dialogObjTitle = '#' + dialogName + 'Title';
    var dialogLink     = '.aa-dialog-' + dialogName;
    var wrapperClass   = 'aa-dialog-content-wrapper';
    var hideCloseInApp = false;
    self.version = 2.1;
    self.jQueryUIVersion = jQuery.map(jQuery.fn.jquery.split("."), function(i) {
        return ("0" + i).slice(-2);
    }).join(".");

    //Init Dialog (Public)
    self.initDialog = function () {
        //Dialog Properties
        props            = (props !== undefined) ? props : {};
        props.hide       = (props.hide !== undefined) ? props.hide : null;
        props.width      = (props.width !== undefined) ? props.width : 882;
        props.width      = (props.width=='small') ? 582 : ((props.width=='medium') ? 840 : ((props.width=='large') ? 926 : props.width));
        props.height     = (props.height !== undefined) ? props.height : 'auto';
        props.maxHeight  = (props.maxHeight !== undefined) ? props.maxHeight : 600;
        props.minHeight  = (props.minHeight !== undefined) ? props.minHeight : 150;
        props.modal      = (props.modal !== undefined) ? props.modal : true;
        props.overlay    = (props.overlay !== undefined) ? props.overlay : true;
        props.cssClass   = (props.cssClass !== undefined) ? props.cssClass : '';
        if (props.showClose !== undefined){
            props.showClose = props.showClose;
        } else{
            if (isMobileApp()){
                props.showClose= false;
            } else{
                props.showClose = true;
            }
        }
        props.showTitle  = (props.showTitle !== undefined) ? props.showTitle : true;
        props.showBusy   = (props.showBusy !== undefined) ? props.showBusy : false;
        props.quickflip  = (props.quickflip !== undefined) ? props.quickflip : false;
        props.buttons    = (props.buttons !== undefined) ? props.buttons : [];
        props.toggleScroll = (props.toggleScroll !== undefined) ? props.toggleScroll : false;
        props.zIndex        = (props.zIndex !== undefined) ? props.zIndex : 1000;
        props.closeOnEscape = (props.closeOnEscape !== undefined) ? props.closeOnEscape : true;
        props.submitOnEnter = (props.submitOnEnter !== undefined) ? props.submitOnEnter : false;
        props.title = (props.title !== undefined) ? props.title : jQuery(dialogObjTitle).prop('value') || jQuery(dialogObjTitle).html();
        props.position      = (props.position !== undefined) ? props.position : 'center';
        props.adaptive      = (props.adaptive !== undefined) ? props.adaptive : true;
        props.onOpen         = (props.onOpen !== undefined) ?  props.onOpen : function(){};
        props.onClose        = (props.onClose !== undefined) ?  props.onClose : function(){};
        props.onBeforeOpen   = (props.onBeforeOpen !== undefined) ?  props.onBeforeOpen : function(){};
        props.onBeforeClose  = (props.onBeforeClose !== undefined) ?  props.onBeforeClose : function(){};
        props.btnPaneContent = (props.btnPaneContent !== undefined) ?  props.btnPaneContent : jQuery(dialogObj).find('#' + dialogName + 'BtnPaneContent').html();
        props.titlePaneContent = (props.titlePaneContent !== undefined) ?  props.titlePaneContent : jQuery(dialogObj).find('#' + dialogName + 'TitlePaneContent').html();
        props.resizable = (props.resizable !== undefined) ? props.resizable : true;
        props.aaPosition = jQuery.extend({vertical: null, horizontal: null, of: null}, props.aaPosition);
        props.showFullScreen = (props.showFullScreen !==undefined) ? (props.showFullScreen && is_phone()) || (props.showFullScreen && isMobileApp()) : false;
        props.attributes = (props.attributes !==undefined) ? props.attributes : {};
        //In aacom mobile app X will be shown on the nav bar when dialogs are opened. For some dialogs like session timeout
        //We don't want that X so we need to send this flag to the component to stop sending message to the app for showing the X.
        hideCloseInApp = (props.hideCloseInApp !== undefined) ? props.hideCloseInApp : false;
        //Overrides dialog values if showFullScreen has been set
        if (props.showFullScreen) {
            var $mainSection = jQuery('#main');
            props.minHeight= jQuery(window).height();
            props.cssClass = 'full-screen-dialog';
            props.height= 'auto';
            props.onOpen =function(){
                $mainSection.hide();
            };
            props.onBeforeOpen = function(){
                if(jQuery('#navigation').hasClass('pageslide')){
                    closePageSlide();
                }
            };
            props.onBeforeClose =function(){
                $mainSection.show();
            };
        }

        // Position of dialog

        dialogArray[dialogObj] = props; //add Dialog

        //Local Variables
        var resizable      = false;
        var draggable      = (props.modal) ? false : true;
        jQuery(dialogObj).addClass('aa-dialog-content-pad');
        if(jQuery(dialogObj).find('.'+wrapperClass).length === 0){
            jQuery(dialogObj).html('<div class="'+wrapperClass+'">'+jQuery(dialogObj).html()+'</div>');
        }

        // Set quickflip before dialog. Very important
        if(props.quickflip) {
            jQuery(dialogObj +' .quickFlip-wrapper').css({'width':props.width-60, 'height':'auto'});

            //quickflip settings if there are flip pannels
            jQuery(dialogObj +' .quickFlip-wrapper').quickFlip({noResize: true});

            jQuery('.ui-dialog .quickFlip-firstPanel').on("click", function() {
                //quickflipper overides the quickFlipCta automatic onclick event ( options, panel #)
                setFlipPanel(0,true);
                return false;
            });

            jQuery('.ui-dialog .quickFlip-secondPanel').on("click", function() {
                setFlipPanel(1,true);
                return false;
            });

            jQuery('.ui-dialog .quickFlip-thirdPanel').on("click", function() {
                setFlipPanel(2,true);
                return false;
            });
        }

        //Jquery Dialog
        jQuery(dialogObj).dialog({
            autoOpen: false,
            modal: props.modal,
            draggable: draggable,
            resizable: resizable,
            bgiframe: true,
            title: props.title,
            width: props.width,
            height: props.height,
            minHeight: props.minHeight,
            zIndex: props.zIndex,
            closeOnEscape: props.closeOnEscape,
            open: props.onOpen,
            close: props.onClose,
            closeText: '',
            beforeClose: props.onBeforeClose,
            dialogClass: props.dialogClass
        });

        //add buttons if they exist
        if(props.buttons.length > 0){
            var btnsArray = [];

            for(var i=0;i<props.buttons.length;i++){
                var btn = props.buttons[i];

                btn.name        = (btn.name !== undefined) ?  btn.name : "OK";
                btn.callback    = (btn.callback !== undefined) ?  btn.callback : function(){};
                btn.cssClass    = (btn.cssClass !== undefined) ?  btn.cssClass : "aa-btn-primary";
                btn.closeDialog = (btn.closeDialog !== undefined) ?  btn.closeDialog : true;
                btn.hideOnpanel = (btn.hideOnpanel !== undefined) ? ","+btn.hideOnpanel+"," : "";
                btn.id = (btn.id !== undefined) ? btn.id : dialogName + "DialogButton" + i;

                if(btn.closeDialog){
                    btn.callback = (function() {
                        var cached_function = btn.callback;
                        return function() {
                            var result = cached_function.apply(this, arguments);
                            jQuery(this).dialog('close');
                            return result;
                        };
                    })();
                }
                btnsArray[i] = {
                    text: btn.name,
                    "class": "aa-btn " + btn.cssClass,
                    "id": btn.id,
                    click: btn.callback
                };
            }

            jQuery(dialogObj).dialog('option', 'buttons', btnsArray );

            if(props.btnPaneContent !== undefined && props.btnPaneContent.length){
                jQuery(dialogObj).parents('.ui-dialog').find('.ui-dialog-buttonpane').prepend(props.btnPaneContent);
            }
        }

        self.initTitleBar(dialogObj,props);

        if(props.toggleScroll) {
            jQuery(dialogObj).bind("dialogclose",function() {
                jQuery("body").css("overflow","auto");
            });
        }
        jQuery(dialogObj).bind("dialogclose",_closeDialog);
        jQuery(dialogObj).bind("dialogopen", _openDialog);
        // Mobile App will trigger this event to tell aacom to close the dialog
        jQuery(document).bind("bridge.web.closeDialog", self.closeDialog);

        if(props.submitOnEnter) {
            jQuery(dialogObj + ' form input').on("keydown", function(e) {
                var keyCode = e.keyCode || e.which;
                if (keyCode == 13) { //enter key
                    jQuery(this).parents('form:first').submit();
                }
            });
        }


        //Open Event
        jQuery(document).on("click", dialogLink, function() {
            self.openDialog(this);
            return false;
        });

        for (let key in props.attributes) {
            if (props.attributes.hasOwnProperty(key)) {
                jQuery(dialogObj).parent().attr(key, props.attributes[key]);
            }
        }
    };

    self.initTitleBar = function(dialogObj,props) {
        var $titleBar = jQuery(dialogObj).parents(".ui-dialog:first").find('.ui-dialog-titlebar');

        var $dialogTitle = $titleBar.find('.ui-dialog-title');
        $dialogTitle.appendTo($dialogTitle.parent());
        $dialogTitle.replaceWith('<h2 tabindex="0" id="'+$dialogTitle.attr('id')+'" class="'+$dialogTitle.attr('class')+'">' + $dialogTitle.html() +'</h2>');
        $dialogTitle.css('margin', '2px');

        self.initCloseIcon($titleBar);

        if(props.showTitle && (props.titlePaneContent !== undefined) && (props.titlePaneContent.length > 0) ){
            $titleBar.append(props.titlePaneContent);
        }
    };

    self.initCloseIcon = function($titleBar) {
        var closeText = (typeof(AAcom) !== 'undefined') ? AAcom.prototype.getProperty('dialog.closeText') : 'Close window';
        var $closeTag = $titleBar.find('.ui-dialog-titlebar-close');
        var $closeIconHiddenText = $closeTag.find('.ui-button-text');
        var $closeIconTag = $closeTag.find('.ui-icon');
        $closeIconHiddenText.text(closeText);
        $closeIconTag.text('');
        if ($closeIconHiddenText.length === 0) {
            $closeTag.append('<span class="ui-button-text hidden-accessible">' + closeText + '</span>');
        }
        $closeTag.attr('id',dialogName+"DialogClose");
    };

    //Open Dialog (Public)
    self.openDialog = function (clickObj) {
        if (dialogObj.length <= 0) return false;

        //Display only one dialog per page.
        if (activeDialog !== '' && activeDialog != dialogObj) {
            if (jQuery(activeDialog).dialog( 'isOpen' )) {
                jQuery(activeDialog).dialog('close');
            }
        }

        //Open Dialog
        activeDialog = dialogObj;
        if (jQuery(dialogObj).dialog('isOpen')) {
            jQuery(dialogObj).dialog('close');
        }
        dialogLinkObj = clickObj;
        var _position = (props.adaptive && is_phone()) ? 'absolute' : 'fixed';
        jQuery(dialogObj).parent(".ui-dialog:first").addClass(props.cssClass);
        jQuery(dialogObj).parent().css('position',_position);
        jQuery(dialogObj).css('position', 'relative');
        jQuery(dialogObj).dialog('option', 'position', props.position);
        props.onBeforeOpen(clickObj);
        jQuery(dialogObj).dialog('open');
        self.resizeDialog();

        var $wrapper = jQuery(activeDialog+' .'+wrapperClass);
        if ($wrapper.find(':input,a,[tabindex=0]').filter(':visible').length === 0) {
            $wrapper.attr('tabindex','0');
        }

        if(props.quickflip && clickObj !== undefined){
            if(jQuery(clickObj).hasClass('quickFlip-secondPanel')) {
                setFlipPanel(1,false);
            }
            else if(jQuery(clickObj).hasClass('quickFlip-thirdPanel')) {
                setFlipPanel(2,false);
            }
            else
            {
                setFlipPanel(0,false);  //set the first panel by default
            }
        }

        if(!props.overlay){
            jQuery('.ui-widget-overlay').css('background','none');
        }
        if (props.adaptive && is_phone()) {
            jQuery(dialogObj).parent(".ui-dialog:first").css('top', getPositionTop());
        }
        if (props.showClose !== null && !props.showClose) {
            jQuery(dialogObj).parents(".ui-dialog:first").find(".ui-dialog-titlebar-close").remove();
        }
        if (props.showTitle !== null && !props.showTitle) {
            jQuery(dialogObj).parents(".ui-dialog:first").find(".ui-dialog-titlebar").remove();
        }
        if (props.showBusy !== null && props.showBusy) {
            self.busyStart('Loading...');
        }

        jQuery(window).on('resize.aaDialog', self.resizeDialog);

        // Scroll top to window, document, or dialog obj
        // Default is dialog, other use the option element
        var elemObj = props.aaPosition.of ? props.aaPosition.of : dialogObj;
        jQuery(elemObj).scrollTop(0);

        self.setFocus();
    };

   // Set focus (Public)
   self.setFocus = function () {
       var $dialog = jQuery(activeDialog).parents(".ui-dialog:first"),
           $dialogTitle = $dialog.find('.ui-dialog-title');

       if ($dialogTitle) {
           $dialogTitle.focus();
       } else {
           $dialog.focus();
       }
    };

    //Close Dialog (Public)
    self.closeDialog = function () {
        if (dialogObj.length <= 0) return false;
        if (jQuery(dialogObj).dialog( 'isOpen' )) {
            jQuery(dialogObj).dialog('close');
        }
    };

    //Close Dialog (Private)
    var _closeDialog = function(ev) {
        activeDialog = '';
        if (self.jQueryUIVersion < '01.10' && jQuery(dialogLinkObj).length > 0) {
            setTimeout(function(){
                jQuery(dialogLinkObj).focus();
                dialogLinkObj = undefined;
            }, 200);
        }
        ev.stopPropagation();
        ev.stopImmediatePropagation();
        if(!hideCloseInApp) {
            jQuery(document).trigger("bridge.aacomToNativeApp", {
                name: "dialogClosed"
            });
        }
        return false;
    };

    //Open Dialog (Private)
    var _openDialog = function () {
        if(!hideCloseInApp) {
            jQuery(document).trigger("bridge.aacomToNativeApp", {
                name: "dialogOpened"
            });
        }
    };


    self.busyStart = function(busyMsg) {
        if (activeDialog.length <= 0) return false;

        var uiParent = jQuery(activeDialog).parents('.ui-dialog:first');
        var uiBusy   = uiParent.find('> .aa-busy-module');
        uiParent.addClass('aa-busy');
        if (busyMsg === undefined) busyMsg = '';
        if (uiBusy.length === 0) {
            uiParent.append('<div class="aa-busy-module"><div class="aa-busy-bg"></div><div class="aa-busy-img"><i class="spinner"></i><span class="text"></span></div></div>');
            uiBusy   = uiParent.find('> .aa-busy-module');
        }
        if (uiBusy.length == 1) {
            uiBusy.find('span').html(busyMsg);
            //uiBusy.show();
        }
    };

    self.busyStop = function() {
        if (activeDialog.length <= 0) return false;

        var uiParent = jQuery(activeDialog).parents('.ui-dialog:first');
        var uiBusy   = uiParent.find('> .aa-busy-module');
        if (uiBusy.length == 1) {
            uiBusy.animate({opacity:'hide'},500,function(){ uiBusy.remove(); uiParent.removeClass('aa-busy'); });
            jQuery(window).resize();
        }
    };

    //Resize dialog (Public)
    self.resizeDialog = function(options) {
        if (activeDialog.length <= 0 || !isResizeEnable()) return false;

        //jQuery("#DialogDebug").append("<br><br>ActiveDialog: "+activeDialog+"<br>ObjDialog: "+dialogObj);

        var activeDialProps = dialogArray[activeDialog];

        var settings = jQuery.extend({
            width: activeDialProps.width,
            height: activeDialProps.height,
            maxHeight: (activeDialProps.adaptive && is_phone()) ? '' : activeDialProps.maxHeight
        }, options);

        var newHeight = settings.height;
        var newWidth = settings.width;
        var maxHeight = settings.maxHeight;

        var paddingTop = parseInt(jQuery(activeDialog).parent().css('padding-top'));
        var paddingBottom = parseInt(jQuery(activeDialog).parent().css('padding-bottom'));
        var paddingLeft = parseInt(jQuery(activeDialog).css('padding-left'));
        var paddingRight = parseInt(jQuery(activeDialog).css('padding-right'));
        var marginLeft = parseInt(jQuery(activeDialog).css('margin-left'));
        var marginRight = parseInt(jQuery(activeDialog).css('margin-right'));
        var titleBar = jQuery(activeDialog).parents(".ui-dialog:first").find(".ui-dialog-titlebar:visible");
        var buttonBar = jQuery(activeDialog).parents(".ui-dialog:first").find(".ui-dialog-buttonpane:visible");

        if(newHeight != 'auto'){
            if (titleBar.length > 0) newHeight -= titleBar.outerHeight();  //titlebar
            if (buttonBar.length > 0) newHeight -= buttonBar.outerHeight();  //buttons
            newHeight -= paddingTop+paddingBottom;  //padding
        }

        jQuery(activeDialog).css('height', newHeight);
        jQuery(activeDialog).css('max-height', maxHeight);

        if(jQuery(window).height() <= jQuery(activeDialog).parent().outerHeight()) {
            if (!(activeDialProps.adaptive && is_phone())) { //set height for non-adaptive designs
                var totalHeight = jQuery(window).height();
                if (titleBar.length > 0) totalHeight -= titleBar.outerHeight();  //titlebar
                if (buttonBar.length > 0) totalHeight -= buttonBar.outerHeight();  //buttons
                totalHeight -= paddingTop+paddingBottom;

                jQuery(activeDialog).css('height',totalHeight*0.8);
                jQuery(activeDialog).css('max-height',totalHeight*0.8);
            }
        }

        if(activeDialProps.toggleScroll) {
            var _overflow = (activeDialProps.adaptive && is_phone()) ? "auto" : "hidden";
            jQuery("body").css("overflow",_overflow);
        }

        jQuery(activeDialog).css('width','auto');
        jQuery(activeDialog).css('max-width', activeDialProps.width);
        jQuery(activeDialog+' .'+wrapperClass).css({'padding-right':0, 'position':'relative', 'margin':'2px'});
        jQuery(activeDialog).parent().css('width',activeDialProps.width);
        jQuery(activeDialog).parent().css('max-width',activeDialProps.width);

        if(jQuery(window).width() <= jQuery(activeDialog).parent().outerWidth()) {
            var scrollbarWidth = 20;
            newWidth -= paddingLeft+paddingRight+marginLeft+marginRight+scrollbarWidth;

            jQuery(activeDialog+' .'+wrapperClass).css({'width':newWidth,'padding-right':paddingRight,'position':'relative'});
            jQuery(activeDialog).parent().css('width',jQuery(window).width() * 0.95);
            jQuery(activeDialog).parent().css('max-width',jQuery(window).width() * 0.95);
        } else {
            jQuery(activeDialog+' .'+wrapperClass).css({'width':''});
        }

        jQuery(activeDialog).dialog('option', 'position', activeDialProps.position);
        if (activeDialProps.adaptive && is_phone()) {
            jQuery(activeDialog).parent(".ui-dialog:first").css('top',jQuery(window).scrollTop()+10);
        }
    };

    //Set FlipPanel (Private)
    var setFlipPanel = function(index,animation) {
        if (activeDialog.length <= 0) return false;

        var activeDialProps = dialogArray[activeDialog];
        var panelHeight = activeDialProps.height;

        if(index === 0)     panelHeight = jQuery(activeDialog).height() - jQuery(activeDialog +' .quickFlip-wrapper .first-flipPanel').height();
        else if(index == 1) panelHeight = jQuery(activeDialog).height() - jQuery(activeDialog +' .quickFlip-wrapper .second-flipPanel').height();
        else if(index == 2) panelHeight = jQuery(activeDialog).height() - jQuery(activeDialog +' .quickFlip-wrapper .third-flipPanel').height();

        var flipSpeed = (animation) ? 200 : 0;
        var flipWidth = (panelHeight > 0) ? activeDialProps.width-60 : activeDialProps.width-77;
        jQuery(activeDialog +' .quickFlip-wrapper').css('width', flipWidth).css('height', 'auto');
        jQuery(activeDialog +' .quickFlip-wrapper').quickFlipper( {noResize: true, closeSpeed: flipSpeed, openSpeed: flipSpeed, refresh:true, panelWidth:flipWidth}, index);
        jQuery(activeDialog +' .quickFlip-wrapper .first-flipPanel').css({'height':'auto','padding-bottom':'30px'});
        jQuery(activeDialog +' .quickFlip-wrapper .second-flipPanel').css({'height':'auto','padding-bottom':'30px'});
        jQuery(activeDialog +' .quickFlip-wrapper .third-flipPanel').css({'height':'auto','padding-bottom':'30px'});

        jQuery(activeDialog).scrollTop(0);
        if(activeDialProps.buttons.length > 0){
            //Hide or show the button(s) if it was passed in the buttons array
            for(var i=0;i<activeDialProps.buttons.length;i++){
                jQuery(activeDialog).parents('.ui-dialog').find('.ui-dialog-buttonpane button').eq(i).show();
                if( activeDialProps.buttons[i].hideOnpanel.indexOf(","+index+",") >= 0){
                    jQuery(activeDialog).parents('.ui-dialog').find('.ui-dialog-buttonpane button').eq(i).hide();
                }
            }
        }
    };

    // Mobile Device (Public)
    var is_phone = function() {
        //Adaptive phone verification
        var result = jQuery('html').hasClass('is-mobile');
        return result;
    };

    //Check Mobile app
    var isMobileApp = function() {
        if(typeof $device !== "undefined" && typeof $device.mobileApp !== "undefined" && $device.mobileApp === true ) {
            return true;
        }
        return false;
    };

    // Disable dialog resize on browser window resize (Private)
    var isResizeEnable = function() {
        if (props && typeof props.resizable === "boolean") {
            return props.resizable;
        }

        return true;
    };

    // Get top coordinate for displaying dialog (Private)
    var getPositionTop = function() {
        if (props && props.aaPosition) {
            switch (props.aaPosition.vertical) {
                case 'top':
                    return 0;
                case 'middle':
                    return jQuery(document).height() / 2;
                case 'bottom':
                    return jQuery(document).height();
            }
        }

        return jQuery(window).scrollTop() + 10;
    };

    //Safe Exit
    if(dialogObj.length <= 0) return false;
    if(jQuery(dialogObj).length <= 0) return false;

    //Setup Dialog Properties
    if (dialogArray[dialogObj] !== undefined && props === undefined) {
        props = dialogArray[dialogObj];
    } else {
        self.initDialog();
    }

}

function aa_Utilities_Format() {
    var self = this;

    /**
     * FUNCTION: formatNumber()
     * Formats number with specific decimals and symbols
     * @param {int} [n]
     * @param {int} [decimals]            Decimal digits         Default('2')
     * @param {string} [decimal_sep]      Thousands separator    Default(',')
     * @param {string} [thousands_sep]    Decimal separator      Default('.')
     */
    self.formatNumber = function(n, decimals, decimal_sep, thousands_sep)
    {
        var c = isNaN(decimals) ? 2 : Math.abs(decimals),
        d = decimal_sep || '.',
        t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        sign = (n < 0) ? '-' : '',
        i = parseInt(n = Math.abs(n).toFixed(c)) + '',
        j = ((j = i.length) > 3) ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
    };

    /**
     * FUNCTION: formatFare()
     * Converts number into money format
     * @param {int} [number]
     * @param {object} [options]
     *     + currency                    Currency Code (3).    Default('USD')
     *     + locale                      Locale code (2 or 5). Default('en' or 'en_US')
     *     + decimals                    Number of decimals    Default('2')
     *     + displayCode                 Display Format        Default('false')
     *     + displaySymbol               Display Format        Default('true')
     *     + displayCurrencyStandard     AA.com Standard       Default('false')
     *     + displayRoundUpNumber        Display whole number  Default('false')

     */
    self.formatFare = function(number,options)
    {
        //Options:
        options               = (options !== undefined) ? options : {};
        options.currency      = (options.currency !== undefined) ? options.currency.toUpperCase() : null;
        options.locale        = (options.locale !== undefined) ? options.locale : null;
        options.decimals      = (options.decimals !== undefined) ? ((!isNaN(options.decimals)) ? Math.abs(options.decimals) : null) : null;
        options.displayCode   = (options.displayCode !== undefined) ? options.displayCode : false;
        options.displaySymbol = (options.displaySymbol !== undefined) ? options.displaySymbol : true;
        options.displayCurrencyStandard = (options.displayCurrencyStandard !== undefined) ? options.displayCurrencyStandard : false;
        options.displayRoundUpNumber = (options.displayRoundUpNumber !== undefined) ? options.displayRoundUpNumber : false;

        //Variables:
        var formatLike = { 'US': {ds:".", ts:","}
                           , 'BR': {ds:",", ts:"."}
                              , 'FR': {ds:",", ts:" "}
                               , 'CH': {ds:".", ts:"'"} };
        var localeLike = { 'US': [ 'ae','au','ca','cn','eg','gb','hk','il','in','jp','sk','th','tw','us' ]
                              , 'BR': [ 'at','br','de','dk','es','gr','it','nl','pt','tr','vn' ]
                              , 'FR': [ 'cz','fi','fr','ru','se','pl' ]
                              , 'CH': [ 'ch' ] };
        var currencies = { 'USD': {code: 'USD', sym: "$"        , fmt: formatLike.US, dec:2 }
                              , 'CAD': {code: 'CAD', sym: "$"        , fmt: formatLike.US, dec:2 }
                              , 'MXN': {code: 'MXN', sym: "$"        , fmt: formatLike.US, dec:2 }
                              , 'GBP': {code: '', sym: "&pound;", fmt: formatLike.US, dec:2 }
                              , 'JPY': {code: 'JPY', sym: "&yen;"    , fmt: formatLike.US, dec:2 }
                              , 'COP': {code: 'COP', sym: "$"        , fmt: formatLike.BR, dec:2 }
                              , 'VEF': {code: 'VEF', sym: "Bs."        , fmt: formatLike.BR, dec:2 }
                              , 'BRL': {code: 'BRL', sym: "R$"        , fmt: formatLike.BR, dec:2 }
                              , 'EUR': {code: 'EUR', sym: "&euro;"    , fmt: formatLike.BR, dec:2 }
                              , 'CLP': {code: 'CLP', sym: "$"        , fmt: formatLike.BR, dec:0 }
                              , 'INR': {code: 'INR', sym: "\u20B9"      , fmt: formatLike.US, dec: 2 }
                              , 'AUD': {code: 'AUD', sym: "$"         , fmt: formatLike.US, dec: 2 }
                              , 'CNY': {code: 'CNY', sym: "&yen;"       , fmt: formatLike.US, dec: 2 }
                              , 'KRW': {code: 'KRW', sym: "\u20A9"      , fmt: formatLike.US, dec: 0 }
                              , 'NZD': {code: 'NZD', sym: "$"         , fmt: formatLike.US, dec: 2 }
                              , 'CHF': {code: 'CHF', sym: "\u20A3"      , fmt: formatLike.US, dec: 2 }
                              , 'HKD': {code: 'HKD', sym: "$"         , fmt: formatLike.US, dec: 2 }
                              , 'NA' : {code: ''      , sym: "$"        , fmt: formatLike.US, dec:2 } };

        if (options.locale !== null) {
            var localeKey = '';
            for (var key in localeLike) {
                var obj = localeLike[key];
                if (localeKey !== '') break;
                if (!localeLike.hasOwnProperty(key)) continue;
                if (typeof(obj) != 'object' && typeof(obj.length) != 'number') continue;
                for (var z=0; z<obj.length; z++) {
                    if (options.locale.toLowerCase() === obj[z]) {
                        localeKey = key;
                        currencies.NA.fmt = formatLike[localeKey];
                        break;
                    }
                }
            }
        }

        var isGBPCurrency = (options.currency === "GBP");
        var isUSDCurrency = (options.currency === "USD");
        if (options.displayCurrencyStandard === true) {
                var isCountryUS = (options.locale === null) || (options.locale.substring(3, 5).toUpperCase() === "US");
                options.displaySymbol = (isUSDCurrency && isCountryUS) || isGBPCurrency;
                options.displayCode = !options.displaySymbol;
        }

        //Select Currency
        var curr;
        if (options.currency !== null)         curr = currencies[options.currency];
        else if (options.locale !== null)     curr = currencies.NA;
        if (curr === undefined)                 curr = currencies.USD;
        if (options.decimals !== null)        curr.dec = options.decimals;
        if (options.displayCode !== true)    curr.code = "";
        if (options.displaySymbol !== true)    curr.sym = "";

        var currencySymbol = curr.sym;
        if (options.displayCurrencyStandard === true && isGBPCurrency) {
        	currencySymbol += " ";
        }

        var numberWithRounding = (options.displayRoundUpNumber === true) ? Math.ceil(number) : number;

        //Output
        var result = '%s%n %c';
        result = result.replace(/%s/g,currencySymbol);
        result = result.replace(/%c/g,curr.code);
        result = result.replace(/%n/g,self.formatNumber(numberWithRounding, curr.dec, curr.fmt.ds, curr.fmt.ts));
        result = result.replace(/^\s+|\s+$/g,"");    // Trim
        return result;
    };
}
